import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import mq, { mqdown } from '@mq';
import { grid, layoutWrapper } from '@mixins';
import RichText from '@core/RichText';
import Link from '@core/Link';
import Seo from '@core/Seo';

const AboutPage = ({
  data: {
    contentfulAboutPage: { text, contact },
  },
}) => (
  <>
    <Seo pageTitle="About" />
    <Main>
      <Text text={text} />
      <Contact text={contact} />
    </Main>
  </>
);

const Main = styled.main`
  ${grid};
  row-gap: 3rem;

  ${mqdown.medium} {
    margin-top: 4.75rem;
  }

  ${mq.medium} {
    margin-top: var(--navigation-padding-block);
  }
`;

const Text = styled(RichText)`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 3 / 10;
  }

  ${mq.large} {
    grid-column: 3 / 8;
  }
`;

const Contact = styled(RichText)`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 3 / 10;
    grid-row: 2;
  }

  ${mq.large} {
    grid-column: 9 / 11;
    grid-row: initial;
  }
`;

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    contentfulAboutPage {
      text {
        raw
      }
      contact {
        raw
      }
    }
  }
`;

export default AboutPage;
